/* eslint-disable */
import React, { useState, useRef, useEffect } from 'react';
import { NumericFormat } from 'react-number-format';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';

export default function App() {

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const { register, getValues, handleSubmit, control, formState: { errors } } = useForm();

    const [isRefinanceCalculator, setIsRefinanceCalculator] = useState(true);
    const [showResults, setShowResults] = useState(false);

    const [fixedRateEstimate, setFixedRateEstimate] = useState({});;
    const [variableRateEstimate, setVariableRateEstimate] = useState({});;

    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    //const defaultDate = new Date(new Date().setFullYear(new Date().getFullYear() - 62));
    //const defaultDateString = new Date(defaultDate.getTime() - (defaultDate.getTimezoneOffset() * 60000)).toISOString().split("T")[0];
    const defaultDateString = new Date(1961, 0, 1).toISOString().split("T")[0];

    const [ageYoungest, setYoungestAge] = useState(0);
    const [propertyValue, setPropertyValue] = useState(0);
    const [fundsAvailable, setFundsAvailable] = useState(0);
    const [mortgagePayoff, setMortgagePayoff] = useState(0);

    const toggleCalculatorType = () => {
        setShowResults(false);
        setIsRefinanceCalculator(!isRefinanceCalculator);
    };

    const getResults = (data) => {

        setShowResults(true);
        setIsLoading(true);
        setShowCta(true);

        let collectedData = data;

        /* 68 */
        collectedData.FixedRateProductId = 68;
        collectedData.VariableRateProductId = 503;

        let currentYear = new Date().getFullYear();
        let dob = new Date(collectedData.DobYoungest);
        let age = currentYear - dob.getFullYear();

        setYoungestAge(age);
        setPropertyValue(collectedData.PropertyValue);
        setFundsAvailable(collectedData.AvailableAssets);
        setMortgagePayoff(collectedData.Liens);

        let apiUrl = '';

        if (isRefinanceCalculator) {
            collectedData.PropertyValue = parseInt(collectedData.PropertyValue.replaceAll(",", ""));
            collectedData.Liens = parseInt(collectedData.Liens.replaceAll(",", ""));

            // Fixed Product Rate Response
            apiUrl = window.lhfs.apiUrl +
                '/ReverseMortgage/Calculator/loan-estimate?homeValue=' +
                collectedData.PropertyValue +
                '&dateOfBirth=' +
                collectedData.DobYoungest +
                '&mortgageLeinDebt=' +
                collectedData.Liens +
                '&state=' +
                collectedData.State +
                '&productId=' +
                collectedData.FixedRateProductId;

            axios
                .get(apiUrl)
                .then((response) => {
                    setFixedRateEstimate(response.data);
                    setIsLoading(false);
                    setShowResults(true);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    setIsError(error);
                });

            // Variable Product Rate Response
            apiUrl = window.lhfs.apiUrl +
                '/ReverseMortgage/Calculator/loan-estimate?homeValue=' +
                collectedData.PropertyValue +
                '&dateOfBirth=' +
                collectedData.DobYoungest +
                '&mortgageLeinDebt=' +
                collectedData.Liens +
                '&state=' +
                collectedData.State +
                '&productId=' +
                collectedData.VariableRateProductId;

            axios
                .get(apiUrl)
                .then((response) => {
                    setVariableRateEstimate(response.data);
                    setIsLoading(false);
                    setShowResults(true);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    setIsError(error);
                });

        }
        else {
            collectedData.AvailableAssets = parseInt(collectedData.AvailableAssets.replaceAll(",", ""));

            // Fixed Product Rate Response
            apiUrl = window.lhfs.apiUrl +
                '/ReverseMortgage/Calculator/purchase-power-estimate?homeValue=' +
                collectedData.AvailableAssets +
                '&dateOfBirth=' +
                collectedData.DobYoungest +
                '&state=' +
                collectedData.State +
                '&productId=' +
                collectedData.FixedRateProductId;

            axios
                .get(apiUrl)
                .then((response) => {
                    setFixedRateEstimate(response.data);
                    setIsLoading(false);
                    setShowResults(true);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    setIsError(error);
                });

            // Variable Product Rate Response
            apiUrl = window.lhfs.apiUrl +
                '/ReverseMortgage/Calculator/purchase-power-estimate?homeValue=' +
                collectedData.AvailableAssets +
                '&dateOfBirth=' +
                collectedData.DobYoungest +
                '&state=' +
                collectedData.State +
                '&productId=' +
                collectedData.VariableRateProductId;

            axios
                .get(apiUrl)
                .then((response) => {
                    setVariableRateEstimate(response.data);
                    setIsLoading(false);
                    setShowResults(true);
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    setIsError(error);
                });

        }
    }

    /*Email Contact Form*/
    const [showCta, setShowCta] = useState(false);
    const [showContactForm, setShowContactForm] = useState(false);
    const scrollRef = useRef(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [messageResponse, setMessageResponse] = useState('');
    const [formSubmittedError, setFormSubmittedError] = useState(false);
    const [formSubmittedSuccess, setFormSubmittedSuccess] = useState(false);

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        brokerCompany: ''
    });

    const handleBrokerContactFormSubmit = (e) => {
        e.preventDefault();
        const token = document.querySelector('input[name="__RequestVerificationToken"]').value;

        const headers = {
            'Content-Type': 'application/json',
            'RequestVerificationToken': token
        };

        axios
            .post('/request-contact', formData, { headers })
            .then((response) => {
                setFormSubmittedSuccess(true);
                setMessageResponse(response.data.message);
                //messageResponse.message = response.data.message;
                console.log('Success:', response);
            })
            .catch(error => {
                setFormSubmittedError(true);
                if (error.response) {
                    // server responded with an error status code (4xx or 5xx)
                    setMessageResponse(error.response.data.message);
                    console.log("error.response", error.response.data.message);
                    // handle the error message as needed
                } else if (error.request) {
                    // request was made but no response was received
                    setMessageResponse(error.request);
                    console.log("error.request", error.request);
                } else {
                    // something else happened in setting up the request
                    setMessageResponse(error.message);
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    };


    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => Object.assign({}, prevFormData, { [name]: value }));
    };

    const handlePhoneChange = (e) => {
        const input = e.target.value.replace(/\D/g, '');
        let formattedPhoneNumber = '';

        // Format the phone number based on the number of digits entered
        if (input.length < 4) {
            formattedPhoneNumber = `(${input.slice(0, 3)}`;
        } else if (input.length < 7) {
            formattedPhoneNumber = `(${input.slice(0, 3)}) ${input.slice(3, 6)}`;
        } else {
            formattedPhoneNumber = `(${input.slice(0, 3)}) ${input.slice(3, 6)}-${input.slice(6, 10)}`;
        }

        setFormData(prevFormData => Object.assign({}, prevFormData, { phone: formattedPhoneNumber }));
    };

    const handleLinkClick = (e) => {
        setShowContactForm(!showContactForm);
        setScrollPosition(window.pageYOffset);
    };

    useEffect(() => {

        //console.log("window.pageYOffset", window.pageYOffset);
        if (showContactForm && scrollRef.current) {
            // Store the scroll position before showing the div
            setScrollPosition(window.pageYOffset);

            // Show the div
            scrollRef.current.style.display = 'block';

            // Restore the scroll position after showing the div
            window.scrollTo(0, scrollPosition);
        }
    }, [showContactForm]);

    return (
        <div className="App">
            <div className="row">
                <div className="col-12">
                    <h1 className="bold text-center">Calculate Your Clients <span className="primary">Future</span></h1>
                    <h2 className="text-center">Let's Insure Your Client Gets the Best Rate.</h2>
                    <form onSubmit={handleSubmit(getResults)}>
                        <div className="calculator calculator-reverse-mortgage calculator-refinance mt-3 col-md-10 offset-md-1">
                            <div className="calculator-row row">
                                <div className="col-12 radio-buttons">
                                    <div className="section-title">
                                        <h4>Is this a refinance of a primary residence or purchase of a new property?</h4>
                                        <div className="radio-container vertical">
                                            <input className="calculator-input format-input trigger-update-fields" name="CalculatorType" type="radio" value="true" checked={isRefinanceCalculator} onChange={toggleCalculatorType} />
                                            <label>Refinance of primary residence</label>
                                        </div>
                                        <div className="radio-container vertical">
                                            <input className="calculator-input format-input trigger-update-fields" name="CalculatorType" type="radio" value="false" checked={!isRefinanceCalculator} onChange={toggleCalculatorType} />
                                            <label>Purchase of new property</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="calculator-row row">
                                <div className="col-12">
                                    <label htmlFor="DateOfBirth">Youngest borrower DOB <br />(must be 62 years of age or older)</label>
                                    <div>
                                        <input className="calculator-input"
                                            name="dob"
                                            type="date"
                                            defaultValue={defaultDateString}
                                            {...register("DobYoungest", { required: true, max: defaultDateString })}
                                        />
                                    </div>
                                    {errors?.DobYoungest?.type === "required" &&
                                        <div className="error-message">This field is required</div>}
                                    {errors?.DobYoungest?.type === "max" &&
                                        <div className="error-message">Youngest borrower must be 62 years of age or older</div>
                                    }
                                </div>
                                <div className="col-12 mt-2">
                                    <label htmlFor="State">State</label>
                                    <div>
                                        <select className="calculator-select"
                                            name="state"
                                            {...register("State", { required: true })}
                                        >
                                            <option value="">-Select-</option>
                                            <option value="AL">Alabama</option>
                                            <option value="AK">Alaska</option>
                                            <option value="AZ">Arizona</option>
                                            <option value="AR">Arkansas</option>
                                            <option value="CA">California</option>
                                            <option value="CO">Colorado</option>
                                            <option value="CT">Connecticut</option>
                                            <option value="DE">Delaware</option>
                                            <option value="DC">District Of Columbia</option>
                                            <option value="FL">Florida</option>
                                            <option value="GA">Georgia</option>
                                            <option value="HI">Hawaii</option>
                                            <option value="ID">Idaho</option>
                                            <option value="IL">Illinois</option>
                                            <option value="IN">Indiana</option>
                                            <option value="IA">Iowa</option>
                                            <option value="KS">Kansas</option>
                                            <option value="KY">Kentucky</option>
                                            <option value="LA">Louisiana</option>
                                            <option value="ME">Maine</option>
                                            <option value="MD">Maryland</option>
                                            <option value="MA">Massachusetts</option>
                                            <option value="MI">Michigan</option>
                                            <option value="MN">Minnesota</option>
                                            <option value="MS">Mississippi</option>
                                            <option value="MO">Missouri</option>
                                            <option value="MT">Montana</option>
                                            <option value="NE">Nebraska</option>
                                            <option value="NV">Nevada</option>
                                            <option value="NH">New Hampshire</option>
                                            <option value="NJ">New Jersey</option>
                                            <option value="NM">New Mexico</option>
                                            <option value="NY">New York</option>
                                            <option value="NC">North Carolina</option>
                                            <option value="ND">North Dakota</option>
                                            <option value="OH">Ohio</option>
                                            <option value="OK">Oklahoma</option>
                                            <option value="OR">Oregon</option>
                                            <option value="PA">Pennsylvania</option>
                                            <option value="RI">Rhode Island</option>
                                            <option value="SC">South Carolina</option>
                                            <option value="SD">South Dakota</option>
                                            <option value="TN">Tennessee</option>
                                            <option value="TX">Texas</option>
                                            <option value="UT">Utah</option>
                                            <option value="VT">Vermont</option>
                                            <option value="VA">Virginia</option>
                                            <option value="WA">Washington</option>
                                            <option value="WV">West Virginia</option>
                                            <option value="WI">Wisconsin</option>
                                            <option value="WY">Wyoming</option>
                                        </select>
                                        {errors?.State &&
                                            <div className="error-message">This field is required</div>}
                                    </div>
                                </div>
                            </div>

                            <div className="calculator-row row">
                                <div className="col-12">
                                    <label htmlFor="propertyType">Property Type</label>
                                    <div className="post-arrow-down">
                                        <select className="calculator-select"
                                            name="propertyType"
                                            {...register("PropertyType", { required: true })}
                                        >
                                            <option value="single-family" defaultValue>Single Family</option>
                                            <option value="condo">Condo in an FHA approved project or site condo</option>
                                            <option value="townhouse">Townhouse (attached or detached)</option>
                                            <option value="dwelling">2-4 Unit Dwelling</option>
                                            <option value="new-construction">New Construction</option>
                                            <option value="manufactured">Manufactured</option>
                                            <option value="other">Other</option>
                                        </select>
                                        {errors?.PropertyType &&
                                            <div className="error-message">This field is required</div>}
                                    </div>
                                </div>
                                {isRefinanceCalculator &&
                                    <>
                                        <div className="col-12 mt-2">
                                            <label htmlFor="propertyValue">Estimated home value</label>
                                            <div className="pre-dollars">
                                                <Controller
                                                    control={control}
                                                    name="PropertyValue"
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, name, value } }) => (
                                                        <NumericFormat
                                                            className="calculator-input"
                                                            thousandSeparator=","
                                                            name={name}
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    )} />
                                            </div>
                                            {errors?.PropertyValue?.type === "required" &&
                                                <div className="error-message">This field is required</div>}
                                        </div>
                                        <div className="col-12 mt-2">
                                            <label htmlFor="liens">Balance of mortgage and/or liens of title to be paid off</label>
                                            <div className="pre-dollars">
                                                <Controller
                                                    control={control}
                                                    name="Liens"
                                                    rules={{ required: true }}
                                                    render={({ field: { onChange, name, value } }) => (
                                                        <NumericFormat
                                                            className="calculator-input"
                                                            thousandSeparator=","
                                                            name={name}
                                                            value={value}
                                                            onChange={onChange}
                                                        />
                                                    )} />
                                            </div>
                                            {errors?.Liens?.type === "required" &&
                                                <div className="error-message">This field is required</div>}
                                        </div>
                                    </>
                                }
                                {!isRefinanceCalculator &&
                                    <div className="col-12 mt-2">
                                        <label htmlFor="availableAssets">Funds available for purchase (must be greater than $100,000)</label>
                                        <div className="pre-dollars">
                                            <Controller
                                                control={control}
                                                name="AvailableAssets"
                                                rules={{ required: true, minLength: 7 }}
                                                render={({ field: { onChange, name, value } }) => (
                                                    <NumericFormat
                                                        className="calculator-input"
                                                        thousandSeparator=","
                                                        name={name}
                                                        value={value}
                                                        onChange={onChange}
                                                    />
                                                )} />
                                        </div>
                                        {errors?.AvailableAssets?.type === "required" &&
                                            <div className="error-message">This field is required</div>}
                                        {errors?.AvailableAssets?.type === "minLength" &&
                                            <div className="error-message">You don�t have enough available funds.</div>}
                                    </div>
                                }
                                <div className={`col-12 radio-buttons ${isRefinanceCalculator ? "mt-2" : "mt-2"}`}>
                                    <label className="mb-1" htmlFor="primaryResidence">Is this your primary residence?</label>
                                    <div className="radio-container">
                                        <input className="calculator-input format-input"
                                            id="primaryResidence"
                                            type="radio"
                                            value="Yes"
                                            {...register("PrimaryResidence", { required: true, minLength: 3 })} />
                                        <label>Yes</label>
                                    </div>
                                    <div className="radio-container">
                                        <input className="calculator-input format-input"
                                            id="primaryResidence"
                                            type="radio"
                                            value="No"
                                            {...register("PrimaryResidence", { required: true, minLength: 3 })} />
                                        <label>No</label>
                                    </div>
                                    {errors?.PrimaryResidence?.type === "required" &&
                                        <div className="error-message">The subject property must be the primary residence of borrower(s). Occupancy must be established.</div>
                                    }
                                    {errors?.PrimaryResidence?.type === "minLength" &&
                                        <div className="error-message">The subject property must be the primary residence of borrower(s). Occupancy must be established.</div>
                                    }
                                </div>
                            </div>
                            <div className="calculator-row justify-content-end">
                                <input id="calculate-btn" className="btn text-uppercase" type="submit" value="Calculate" />
                            </div>
                        </div>
                    </form>
                    {/*<div className="mt-1 mb-2 text-center disclaimer-links">*/}
                    {/*    <div><a href="https://lhfs.com/calculators/calculation-disclosures-and-assumptions/" className="secondary">*View calculation assumptions and disclosure</a></div>*/}
                    {/*    <a href="https://lhfs.com/calculators/">View All Calculators  ></a>*/}
                    {/*    */}{/*<p className="disclaimer--red text-center">Broker loans are not permitted in the state of North Carolina. For more information, please contact <a href="mailto:reverse@lhfs.com">reverse@lhfs.com</a>.</p>*/}
                    {/*</div>*/}
                    {isLoading && <h2 className="text-center">Loading...</h2>}
                    {showResults &&
                        <div className="calculator-reverse-mortgage results">
                            {isError && <div>{isError}</div>}
                            <div className="calculator calculator-reverse-mortgage calculator-refinance mar-top--2 results-container">
                                <div className="calculator-row pb-0">
                                    <div className="col-sm-12 col-md-6 col-lg-5 results">
                                        <div>
                                            <h3 className="text-uppercase bold">{fixedRateEstimate.productTypeName}<sup>(1)</sup></h3>
                                        </div>
                                        <div>
                                            <span>AGE: {ageYoungest}</span>
                                        </div>
                                        {isRefinanceCalculator
                                            ? <div>
                                                <span>Property Value: {propertyValue}</span>
                                                <span className="descriptor">(Estimate)</span>
                                            </div>
                                            : <div>
                                                <span>Funds Available for Purchase: ${fundsAvailable}</span>
                                            </div>
                                        }
                                        {isRefinanceCalculator
                                            ? <div>
                                                <span>Proceeds for Mortgage Payoff: ${mortgagePayoff}</span>
                                            </div>
                                            : <div>
                                                <span>Potential Purchase Price: {formatter.format(fixedRateEstimate
                                                    .purchasePower)}</span>
                                            </div>
                                        }
                                        {isRefinanceCalculator &&
                                            <div>
                                                <span>Loan Proceeds Available: <sup>(2,3)</sup> {formatter.format(fixedRateEstimate
                                                    .availableFunds)}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="calculator-row pb-0">
                                    <div className="col-sm-12 col-md-6 col-lg-5 results">
                                        <div>
                                            <h3 className="text-uppercase bold">{variableRateEstimate.productTypeName}<sup>(1)</sup></h3>
                                        </div>
                                        <div>
                                            <span>AGE: {ageYoungest}</span>
                                        </div>
                                        {isRefinanceCalculator
                                            ? <div>
                                                <span>Property Value: {propertyValue}</span>
                                                <span className="descriptor">(Estimate)</span>
                                            </div>
                                            : <div>
                                                <span>Funds Available for Purchase: ${fundsAvailable}</span>
                                            </div>
                                        }
                                        {isRefinanceCalculator
                                            ? <div>
                                                <span>Proceeds for Mortgage Payoff: ${mortgagePayoff}</span>
                                            </div>
                                            : <div>
                                                <span>Potential Purchase Price: {formatter.format(variableRateEstimate
                                                    .purchasePower)}</span>
                                            </div>
                                        }
                                        {isRefinanceCalculator &&
                                            <div>
                                                <span>Loan Proceeds Available: <sup>(2,3)</sup> {formatter.format(
                                                    variableRateEstimate.availableFunds)}</span>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="results-fine-print">
                                    <ol className="">
                                        <li>Calculations above are based on default loan programs. The results of this calculator are for illustrative purposes only and are based on today's available rate/margin.</li>
                                        {/*<li>All information provided above is for informational purposes only, for the exclusive use of Real Estate professionals, and not for the general public.</li>*/}
                                        {isRefinanceCalculator &&
                                            <li>Closing costs are not included. "Loan Proceeds Available" is an estimate based upon data provided above.</li>
                                        }
                                        {isRefinanceCalculator &&
                                            <li>The actual amount a borrower may receive is based upon the following:
                                                <ul>
                                                    <li>Youngest borrower.</li>
                                                    <li>Current interest rates.</li>
                                                    <li>The lesser of the appraised value of borrower's home, sale price or the maximum loan program lending limit.</li>
                                                </ul>
                                            </li>
                                        }
                                    </ol>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {/*{showCta && (*/}
            {/*    <div className="col-md-10 offset-md-1">*/}
            {/*        <div className="calculator calculator-refinance calculator-reverse-mortgage-cta border mar-top--2">*/}
            {/*            <div className="cta-header">*/}
            {/*                <div className="section-title">*/}
            {/*                    <h1>Ready to Proceed to the Next Step?</h1>*/}
            {/*                    <a href="/quote-proposal"><h2>Click Here to Get a Quote Now!</h2></a>*/}
            {/*                    <h3>OR</h3>*/}
            {/*                    <h2><a href="#" onClick={() => handleLinkClick()}>Have Someone from LHFS Contact Me!</a></h2>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            {showContactForm && (*/}
            {/*                <div ref={scrollRef} style={{ display: 'none' }} className="calculator calculator-refinance mar-top--2">*/}
            {/*                    <form onSubmit={handleBrokerContactFormSubmit}>*/}
            {/*                        <div className="row item-full">*/}
            {/*                            <div className="col-sm-12 col-md-6 col-lg-6">*/}
            {/*                                <input type="text" placeholder="Broker Company" id="brokerCompany" name="brokerCompany" value={formData.brokerCompany} onChange={handleInputChange} />*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="calculator-row row">*/}
            {/*                            <div className="col-sm-12 col-md-6 col-lg-6">*/}
            {/*                                <input type="text" placeholder="Broker First Name" id="broker-first-name" name="firstName" value={formData.firstName} onChange={handleInputChange} />*/}
            {/*                            </div>*/}
            {/*                            <div className="col-sm-12 col-md-6 col-lg-6">*/}
            {/*                                <input type="text" placeholder="Broker Last Name" id="broker-last-name" name="lastName" value={formData.lastName} onChange={handleInputChange} />*/}
            {/*                            </div>*/}
            {/*                            <div className="col-sm-12 col-md-6 col-lg-6">*/}
            {/*                                <input type="text" placeholder="Email Address" type="email" id="email" name="email" value={formData.email} onChange={handleInputChange} />*/}
            {/*                            </div>*/}
            {/*                            <div className="col-sm-12 col-md-6 col-lg-6">*/}
            {/*                                <input type="text" placeholder="Telephone Number" type="tel" id="telephone" name="phone" value={formData.phone} onChange={handlePhoneChange} />*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="calculator-row justify-content-end">*/}
            {/*                            <input id="contact-btn" className="btn" type="submit" value="Submit" />*/}
            {/*                        </div>*/}
            {/*                        <input type="hidden" name="__RequestVerificationToken" value="@Html.AntiForgeryToken()" />*/}
            {/*                        {formSubmittedError && <div className="error">{messageResponse}</div>}*/}
            {/*                        {formSubmittedSuccess && <div className="success">{messageResponse}</div>}*/}
            {/*                    </form>*/}

            {/*                    <div className="disclaimer">*/}
            {/*                        <p>*/}
            {/*                            Please note that all information provided above is for informational purposes only, for the exclusive use of Real Estate professionals,*/}
            {/*                            and not for the general public. Land Home Financial, Inc. (LHFS) is committed to keeping your email address confidential. We do not sell,*/}
            {/*                            rent or lease subscription lists to third parties and we will not provide your personal information to any third party, individual,*/}
            {/*                            government agency or company at any time unless compelled to do so by law. We will use your email address solely to provide information*/}
            {/*                            that�s relevant to your request. We will maintain the information you provide via email in accordance with applicable federal law. By*/}
            {/*                            submitting your phone number you are providing your signature and express �written� consent to having LHFS or our mortgage partners*/}
            {/*                            contact you about your inquiry at the phone number you have provided. You agree to be contacted via a live or automated prerecorded*/}
            {/*                            telephone call, text message, or email even if you have previously registered on a �do not call� government registry or requested*/}
            {/*                            LHFS to not send marketing information to you. You understand that your telephone company may impose charges on you for these contacts,*/}
            {/*                            and you are not required to enter into this agreement as a condition of any LHFS products or services.*/}
            {/*                        </p>*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*            )}*/}

            {/*            <div className="calculator-row row info">*/}
            {/*                <p>For more information regarding borrower eligibility and programs guidelines see below:</p>*/}
            {/*                <ul>*/}
            {/*                    <li><a href="https://cdn.lhfs.com/lhfscdn/wholesale/download/Reverse_FAQ_TPO.pdf" target="_blank">Reverse FAQs now!</a></li>*/}
            {/*                    <li><a href="/reverse-mortgages#reverse-mortgage-library">LHFS' Reverse Mortgage Library</a></li>*/}
            {/*                </ul>*/}
            {/*                <p>LHFS' dedicated Reverse Mortgage Team is here to assist!*/}
            {/*                    For questions contact a Reverse Specialist at 800-398-0865, email<a href="mailto:Reverse@LHFS.com"> Reverse@LHFS.com</a>, or contact your Account Executive.</p>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
}