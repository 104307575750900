/* eslint-disable */
//import $ from 'jquery';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from '../react/ReverseMortgageCalculator';

class ReverseMortgageCalculator {
    constructor() {
        this.container = document.querySelector('#ReverseMortgageCalculator');

        if (this.container) {
            const root = createRoot(this.container);
            root.render(<App />);
        }
    }

    init() {
        this.bindEvents();
    }

    bindEvents() {

    }
}

export default ReverseMortgageCalculator;