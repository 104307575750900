import $ from 'jquery';
import 'babel-polyfill';
import 'popper.js';
import 'bootstrap';

//import Common from './components/common';
//import Layout from './components/layout';
//import Validation from './components/validation';
import ReverseMortgageCalculator from './components/reverseMortgageCalculator';

$().ready(e => {
    //let common = new Common();
    //common.init();

    //let layout = new Layout();
    //layout.init();

    //let validation = new Validation();
    //validation.init();

    let reverseMortgageCalculator = new ReverseMortgageCalculator();
    reverseMortgageCalculator.init();

    //let externalLinkEditor = new ExternalLinkEditor();
    //externalLinkEditor.init();

    //let newUserRegistration = new NewUserRegistration();
    //newUserRegistration.init();
});
